import React, { useEffect } from 'react';
import Layout from '../../../components/layout';
import Vacancy from '../../../components/shared-components/vacancy';
import { navigate } from 'gatsby';

const CustomerSupportSpecialist = () => {
  useEffect(() => {
    navigate('/careers/customer-success-specialist');
  }, []);

  return (
    <Layout>
      <Vacancy
        division="Support"
        vacancy="Customer Success Specialist"
        workType="Full time"
        // location="Yerevan, Armenia"
        intro={
          <>
            <p>
              We are looking for an experienced Customer Success Specialist who will work directly with our
              English-speaking customers all over the world.
            </p>
            <p>
              Main responsibilities include resolving customer queries, recommending solutions, and guiding product
              users through features and functionalities.
            </p>
          </>
        }
        jobResponsibilities={
          <ul>
            <li className="secondary-text">Respond to customer queries via email and chat</li>
            <li className="secondary-text">Provide detailed information about the products</li>
            <li className="secondary-text">Listen to customer issues and provide adequate solutions</li>
            <li className="secondary-text">
              Keep the record of customers and follow-up with them on a timely basis for a better customer experience
            </li>
            <li className="secondary-text">Maintain a good relationship between customers and the company</li>
            <li className="secondary-text">Communicate effectively with the IT team.</li>
          </ul>
        }
        qualifications={
          <ul>
            <li className="secondary-text">Proven customer support experience in the tech industry</li>
            <li className="secondary-text">Excellent written and verbal knowledge of English</li>
            <li className="secondary-text">The flexibility to work according to US hours</li>
            <li className="secondary-text">Excellent problem-solving skills</li>
            <li className="secondary-text">A positive attitude and the ability to build relationships with clients.</li>
          </ul>
        }
      />
    </Layout>
  );
};

export default CustomerSupportSpecialist;
